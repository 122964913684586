.sketchy_table {
    width: 100%;
    border: 2px solid #3333335e;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    letter-spacing: 0.3ch;
    background: #ffffff;
    position: relative;
}

.sketchy_table::before {
    content: '';
    border: 1px solid #35353589;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}