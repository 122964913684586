.App {
  min-height: 100vh;
}


::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

/* Style the thumb */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: lightgray;
}

/* Style the thumb when hovering */
::-webkit-scrollbar-thumb:hover {
  background-color: darkgray;
}