.sketchy_login {
    position: relative;
    padding: 200px 100px;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 2px solid #3333335e;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    letter-spacing: 0.3ch;
    background: #ffffff;
    position: relative;
}

.sketchy_login::before {
    content: '';
    border: 1px solid #35353589;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}


.neobrutal_login {
    font-family: "Darker Grotesque" !important;
    gap: 30px;
    margin: auto;
    width: 800px;
    height: 70vh;
    margin-top: 100px;
    padding: 50px;
    border: 2px solid black;
    background-color: snow;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.shadow {
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 1);
    -webkit-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 1);
}

.heading {
    font-family: initial;
    font-weight: 900;
    font-size: 30px;
    text-decoration: underline;
    justify-self: flex-start;
}

button[type="submit"] {
    font-family: initial;
    box-shadow: 10px;
    background-color: purple;
    color: white;
    letter-spacing: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 1);
    box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 1);
}

.inp {
    border: 1px solid black !important;

}

label {
    font-weight: 500 !important;
}

label::before {
    content: "" !important;
}