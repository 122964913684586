.sketchy {
    padding: 4px 8px;
    display: inline-block;
    border: 3px solid #333333;
    font-size: 20px;
    border-radius: 2% 6% 5% 4% / 1% 1% 2% 4%;
    text-transform: uppercase;
    letter-spacing: 0.3ch;
    background: #ffffff;
    position: relative;
}

.sketchy:hover {
    zoom: 1.03;
    cursor: pointer;
}

.sketchy::before {
    content: '';
    border: 2px solid #353535;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.015) rotate(0.5deg);
    border-radius: 1% 1% 2% 4% / 2% 6% 5% 4%;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
    width: 100%;
}

.sketchy-button {
    cursor: pointer;
    padding: 2px 4px;
    display: inline-block;
    border: 3px solid #333333;
    font-size: 15px;
    height: fit-content;
    background: #ffffff;
}